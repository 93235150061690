import {useState, useEffect } from 'react'
import EstadoCuentas from './estado_cuentas.js'
import Vencidas from './vencidas.js'
import VencidasSoc from './vencidas_social.js'

export default function BuscarSocioCob(props) {
    const [ultima_s, setUltima_s] = useState([])
    const [ultima_d, setUltima_d] = useState([])
    const [hoy_s, setHoy_s] = useState([])
    const [hoy_d, setHoy_d] = useState([])
    
    useEffect (() => {
        function traer_detallec(que,donde) {
            fetch(props.back + 'admin/deta_cuota/id/' + que, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => donde(json))
        }
    traer_detallec(props.socio.ultima_s, setUltima_s)
    traer_detallec(props.socio.ultima_d, setUltima_d)
},[]) 

    useEffect (() => {
        function traer_ultima(que, donde) {
            fetch(props.back + 'admin/ultima_cuota/tipo/' + que, {
                method: 'GET',
                headers: {
                  'Authorization': 'Bearer ' + props.token,
                  'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => donde(json))
        }
    traer_ultima('socio', setHoy_s)
    traer_ultima('actividad', setHoy_d) 
},[]) 


return (<>
    <div className="text-center w-full text-2xl mb-3">
        {props.socio.apellido +' ' + props.socio.nombre}
    </div>
    <div className="grid grid-cols-2">
        <EstadoCuentas socio={props.socio} ultima={ultima_s}  cual='s'/>
        <EstadoCuentas socio={props.socio} ultima={ultima_d}  cual='d'/>
        <Vencidas socio={props.socio} importes={props.importes} vencida_s={props.vencida_s} setVencida_s={props.setVencida_s} valor_s={props.valor_s} />
        <VencidasSoc socio={props.socio}  actividades={props.actividades} vencida_d={props.vencida_d} setVencida_d={props.setVencida_d} valor_d={props.valor_d} />
     </div>
</>)
            
}

export default function Totales (props) {
 
    return (<>
    <div className="grid grid-cols-3 gap-8 mx-40 items-center my-5">
        <div className="flex flex-row justify-between">
            <label className="px-2 text-2xl justify-self-start"> TOTAL </label>
            <label className="px-2 text-2xl justify-self-end">{props.total_soc + props.total_dep}</label>
        </div>
        <div className="flex flex-col justify-center text-lg">
            <div className="flex flex-row items-center ">
                <input 
                    type="radio"
                    value='E'
                    checked={props.f_p==='E'}
                    onChange={e => props.setF_p(e.target.value)}
                />
                <label className="ml-5">Efectivo</label>
            </div>
            <div className="flex flex-row justify-start items-center">
                <input 
                   type="radio"
                   value='T'
                   checked={props.f_p==='T'}
                   onChange={e => props.setF_p(e.target.value)}
                />
                <label className="ml-5">Transferencia</label>
            </div>
        </div>
        <button 
            className="bg-green-600 text-white p-2 rounded-xxl mx-20"
            onClick={e => props.cobrar()}    
        >
        COBRAR
        </button>
    </div>
    </>)
}
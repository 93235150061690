import BuscarSocioCob from './buscar_socio.js'
import Cobrar from './cobrar.js'
import Totales from './totales.js'
import {useState, useEffect} from 'react'
import dayjs from 'dayjs'

export default function Socios(props) {
    const [total_soc, setTotal_soc] = useState(0)
    const [total_dep, setTotal_dep] = useState(0)
    const [total, setTotal] = useState(0)
    const [f_p, setF_p] = useState('E')
    const [vencida_s, setVencida_s] = useState([])
    const [valor_s, setValor_s] = useState('')
    const [vencida_d, setVencida_d] = useState([])
    const [valor_d, setValor_d] = useState([])

    useEffect (() => {
        function traer (id, tipo, donde) {
            fetch(props.back + 'admin/toda_cuota/id/' + id + '/tipo/' + tipo, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token,
                    'Content-Type': 'application/json' 
                }
            })
            .then (response => response.json())
            .then (json => {
                const t = json.map(a => {
                    return {...a, s: false}
                })
                donde(t)
            })
        }
        function categoria () {
            if (! props.socio.id) return
            const hoy = dayjs(new Date())
            const nac = dayjs(props.socio.nacimiento)
            const ed = hoy.diff(nac, 'year')

            const alta_soc = dayjs(props.socio.alta_sistema)
            const ant = hoy.diff(alta_soc, 'year')

            var cual = 'Menor'
            if (ed>5 && ed<18) cual='Cadete'
            if (ed>18 && ed<60 && props.socio.sexo==='F') cual='Dama'
            if (ed>18 && ed<60 && props.socio.sexo==='M') cual='Activo'
            if (ed>60 && ant <=30) cual='Jubilado'
            const v = props.importes.filter(a => a.cate === cual)
            if (props.socio.bonifi_s)
                setValor_s(v[0].valor /100.0 * (100 - props.socio.bonifi_s))
            else
                setValor_s(v[0].valor)
        }
        
        function actividad () {
            if (! props.socio.id) return
            const act = props.socio.actividad.map(a => { 
                const zzz = props.actividades.map(e => {
                    return e.id === a.actividadId ? e.grupo : ''
                })
                return zzz.filter(t => t!== '')
            })
            const v = act.map(e => {
                const u = props.imp_social.map(a => {
                    return e[0] === a.grupo ? a._avg.valor : 0
                })
                const listo = u.filter(t => t)
                    if (props.socio.bonifi_a)
                        return {act: e[0], valor: listo[0]/100.0* (100-props.socio.bonifi_a)}
                    else
                        return {act: e[0], valor: listo[0]}
            })          
            setValor_d(v)
        }
        if(props.socio.id) {
            traer(props.socio.ultima_s, 'socio', setVencida_s)
            traer(props.socio.ultima_d, 'actividad', setVencida_d)
        }
        categoria()
        actividad()
    },[props.socio])

    useEffect(() =>{
        if(!props.socio.id) return
        function calcular_s() {
            var t=0
            if (vencida_s!==undefined) 
                t = vencida_s.filter(a => a.s).length * valor_s
            else
                t = valor_s
            setTotal_soc(t)
        }
        calcular_s()
    },[vencida_s])

    useEffect(() => {
        if(!props.socio.id) return
        function calcular_d() {
            var tot=0, t=0
            valor_d.map(a => tot += a.valor)
            if (vencida_d!==undefined)
                t = vencida_d.filter(a => a.s).length * tot
            else    
                t = tot
            setTotal_dep(t)
        }
        calcular_d()
    }, [vencida_d])

    function cobrar() {
        console.log(vencida_s)
        console.log(valor_s)
        console.log(vencida_s)
        console.log(valor_d)
    }
    return (<>
    <div className="h-screen flex flex-col">
    {!props.socio.id ?
        <div className="max-h-1/2 p-2">
            <BuscarSocioCob back={props.back} token={props.token} setSocio={props.setSocio} socios={props.socios} setRecargar={props.setRecargar} recargar={props.recargar}/>
        </div>
    : 
        <div className="h-full">
        <div className="p-2 min-h-[70%] overflow-y-auto">
            <Cobrar back={props.back} token={props.token} setSocio={props.setSocio} setRecargar={props.setRecargar} recargar={props.recargar} socio={props.socio} actividades={props.actividades}
             vencida_s={vencida_s} setVencida_s={setVencida_s} vencida_d={vencida_d} setVencida_d={setVencida_d} valor_s={valor_s} valor_d={valor_d} />
        </div>
        <div className="border-t-2 border-gray-600">
            <Totales total_soc={total_soc} total_dep={total_dep} total={total} f_p={f_p} setF_p={setF_p} setTotal={setTotal} cobrar={cobrar} />
        </div>
        </div>
    }


    </div>
    </>)
}